import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import buildLink from "../../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import MessageAlert from "../../../../components/messageAlert";
import hocStarredPage from "../../../../components/hocStarredPage";
import Cookies from "js-cookie";

const GivenToSellerv2 = () => {
  const [orderStatus, setOrderStatus] = useState("normal");
  const orderId = useRef(null);
  const itemRef = useRef(null);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const options = [
    { value: "normal", label: "Normal" },
    { value: "return", label: "Return" },
  ];
  const history = useNavigate();

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  async function handleChangeStatus() {
    const userID = getUserIdFromLocalStorage()
    setLoading(true);
    const obj = {
      logistic_id: "",

      user_id: userID,
      type: "given_to_seller",
      nb_of_pack: "",
      upc_id: "",
      item: itemRef.current.value,
    };
    var url = "";
    if (orderStatus === "normal") {
      obj.order_id = orderId.current.value;
      url = buildLink("updateOrder");
    } else {
      obj.return_order_id = orderId.current.value;
      url = buildLink("updateReturnOrder");
    }
    try {
      await axios.put(url, obj).then((response) => {
        if (response.data.success) {
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
        }
      });
    } catch (e) {
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      setLoading(false);
    }
  }
  useEffect(() => {
    orderId.current.focus();
  }, []);

  return (
    <>
      <div className=" h-screen overflow-hidden relative bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Given To Seller</h2>
            </div>
          </div>
        </div>
        <div className=" container">
          <div className=" py-3">
            <div>Order Status</div>
            <Select
              placeholder="Order Status"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "red",
                  padding: "8px 5px",
                }),
              }}
              onChange={(e) => setOrderStatus(e.value)}
              options={options}
            />
          </div>
        </div>
        <div className="gap-2 flex py-3 ml-3 mr-3">
          <div className="relative flex-1">
            <span>Order ID</span>
            <input
              ref={orderId}
              type="text"
              className="border mb-3 border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
              placeholder="Order Id"
            />
            <span>Item</span>
            <input
              ref={itemRef}
              type="text"
              className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
              placeholder="Item"
            />
          </div>
        </div>
        <div className="w-full container">
          <button
            onClick={() => handleChangeStatus()}
            className="w-full bg-dbase text-white p-2 rounded-md mt-4"
          >
            {loading ? "Loading..." : "Update"}
          </button>
        </div>
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(GivenToSellerv2);
