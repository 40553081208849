import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import { json, useNavigate } from "react-router-dom";
import { CompanyContext } from "../contexts/companyCotext";
import buildLink from "../urls";
import axios from "axios";
import MessageAlert from "../components/messageAlert";
import Cookies from "js-cookie";

const LogIn = () => {
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const [state, dispatch] = useContext(CompanyContext);
  const [isshowPass, setisShowPass] = useState(false);
  const name = useRef("");
  const pass = useRef("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [host, setHost] = useState(window.location.host);

  useEffect(() => {
    setHost(window.location.host);
  }, [host]);


  

  // useEffect(() => {
  //   const logoutAtMidnight = () => {
  //     const now = new Date();
  //     const midnight = new Date(now);
  //     midnight.setHours(24, 0, 0, 0); // Set to midnight

  //     const timeUntilMidnight = midnight - now;
  //     setTimeout(() => {
  //       // Logout user when it's past midnight
  //       logout();
  //     }, timeUntilMidnight);
  //   };

  //   logoutAtMidnight();
  // }, [login]);





  // useEffect(() => {
  //   const checkLastAccessDate = () => {
  //     const lastAccessDate = localStorage.getItem('lastAccessDate');
  //     const currentDate = new Date().toDateString();
  //     if (lastAccessDate !== currentDate) {
  //       logout();
  //       localStorage.setItem('lastAccessDate', currentDate);
  //     }
  //   };
  //   checkLastAccessDate();
  // }, [login]);

  // useEffect(() => {
  //   const currentDate = new Date().toDateString();
  //   localStorage.setItem('lastAccessDate', currentDate);
  // }, []);





  // const logout = () => {
  //   localStorage.removeItem("username");
  //   localStorage.removeItem("password");
  //   localStorage.removeItem("userID");
  //   dispatch({ type: "setUsername", payload: "" });
  //   dispatch({ type: "setLoged", payload: false });
  //   dispatch({ type: "setUserId", payload: "" });
  //   dispatch({ type: "storeList", payload: "" });
  //   history("/login", { replace: true });
  // };

  async function login(e) {
    setLoading(true);
    e.preventDefault();
    const username = name.current.value;
    const password = pass.current.value;
    const obj = {
      username,
      password,
      
    };
    if(window.location.host === 'pda.flo-lebanon.com' || window.location.host === "pda.englishhome.com.lb" || window.location.host.startsWith("localhost")){
      obj.web_pda = true
    }

    try {
      await axios.post(buildLink("login"), obj).then((response) => {
        if (response.data.success == true) {
          const currentDate = new Date().getDate();
          Cookies.set('dateLoged', currentDate);
          dispatch({ type: "setUsername", payload: username });
          dispatch({ type: "setLoged", payload: true });
          dispatch({ type: "setUserId", payload: response.data.data.user_id });
          dispatch({ type: "setStoreList", payload: response.data.data.stores });
          // dispatch({ type: "setStoreId",   payload: response.data.data.store_id,});
          if(  window.location.host == 'pda.flo-lebanon.com' || window.location.host === "pda.englishhome.com.lb"){
            dispatch({ type: "setCompany", payload: response.data.data.store_name });
            dispatch({ type: "setStoreName", payload: response.data.data.store_name });
            dispatch({ type: "setStoreId", payload: response.data.data.store_id });
            dispatch({ type: "setStoreList", payload: response.data.data.stores });
            localStorage.setItem('stores', JSON.stringify(response.data.data.stores));
            }
          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
          localStorage.setItem("userID", response.data.data.user_id);
          localStorage.setItem("store_id", response.data.data.store_id);
          localStorage.setItem("store_name", response.data.data.store_name);
          // if (response.data && response.data.data && response.data.data.stores) {
          //   localStorage.setItem("stores", JSON.stringify(response.data.data.stores));
          // } else {
            
          // }
          if (response.data && response.data.data && response.data.data.stores) {
            localStorage.setItem('stores', JSON.stringify(response.data.data.stores));
          } else {
            
          }
          
          history("/home", { replace: true });
        }
      });
    } catch (e) {
      setMessage(e.response.data.message);
      setShowMessageErr(true);
    }
    setLoading(false);
  }

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative flex-row justify-between  w-full text-white  h-full my-auto">
              <h2 className=" text-xl    my-auto"> {state.company} </h2>
            </div>
          </div>
        </div>
        <div className=" container  flex flex-col justify-center gap-5 text-center">
          <h2 className=" text-2xl font-bold">Welcome Back!</h2>
          <form className=" flex flex-col gap-4">
            <input
              ref={name}
              type="text"
              className=" border-dlabelColor  border px-3 py-3 rounded-md"
              placeholder="Username"
            />
            <div className="  relative w-full">
              <input
                ref={pass}
                type={isshowPass ? "text" : "password"}
                className=" w-full border-dlabelColor  border px-3 py-3 rounded-md"
                placeholder="Password"
              />
              <div
                onClick={() => {
                  setisShowPass(!isshowPass);
                }}
                className=" my-auto h-fit  absolute top-0 right-4 bottom-0 "
              >
                {" "}
                {isshowPass ? (
                  <FaEye className=" text-dlabelColor text-xl" />
                ) : (
                  <FaEyeSlash className=" text-dlabelColor text-xl" />
                )}
              </div>
            </div>
            <button
              type="submit"
              onClick={(e) => {
                login(e);
              }}
              className=" bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
            >
              {" "}
              {loading ? "loading..." : "Login"}
            </button>
          </form>
        </div>
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default LogIn;
