import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaEye, FaPlus, FaSave, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import Cookies from "js-cookie";

const JardehViewAll = () => {
  const {playErrorSound,playSuccessSound} = PlaySounds();
const history = useNavigate();
const{idJardeh}= useParams();
const [stateCom , dispatchCom]= useContext(CompanyContext);
const [jardehProducts,setJardehProducts]= useState(null);
const [showMessgSucc, setShowMessageSucc] = useState(false);
const [message, setMessage] = useState("");
const [showMessgErr, setShowMessageErr] = useState(false);

let selectedRows =[];

const handleCheckboxChange = (e,id) => {
    const checked = e.target.checked
   if(checked){
    selectedRows.push(id);
   }else{
    selectedRows =  selectedRows.filter(item => item !== id);
   }
   console.log(selectedRows)
};




async function getJardehProducts() {
  try{   await axios.get(buildLink("jardehProducts")+"&jardeh_id="+idJardeh) .then((res) => {
      setJardehProducts(res.data.data);
  })
}catch(e){
  playErrorSound()
  setMessage(e.response.data.message);
  setShowMessageErr(true);
  setTimeout(() => {
      setShowMessageErr(false);
    }, 3000);
}

}
  
const getUserIdFromLocalStorage = () => {
  return localStorage.getItem("userID");
};


  async function deletJardehProducts(){
    const userID = getUserIdFromLocalStorage()
      const url = buildLink('deleteJardehProducts');
      const obj = {
        ids:selectedRows,
        user_id: userID
      }
      try{ 
        await axios.delete(url, { data: obj }).then((res)=>{
          playSuccessSound()
            setMessage(res.data.message)
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
            }, 3000);
        })
      }catch(e){
        playErrorSound()
        setMessage(e.response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
            setShowMessageErr(false);
          }, 3000);
      }

  }
  
  
  useEffect(()=>{
    getJardehProducts()
  },[])
  
  return (
    <>
      <div className=" relative  overflow-x-hidden h-screen bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">View All</h2>
            </div>
            <div className=" flex flex-row justify-end text-end gap-10 ">
            <div className=" h-fit my-auto">{jardehProducts && jardehProducts.length}</div>
            <button onClick={()=>deletJardehProducts()} ><FaSave/></button>
            </div>
           </div>
          </div>
        </div>
        <div className=" max-w-screen overflow-x-auto relative">
        {jardehProducts&& 
             <table className="min-w-full divide-y divide-dgrey ">
                   <thead className="bg-dgrey">
        <tr>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dblack uppercase tracking-wider">
            Product
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dblack uppercase tracking-wider">
            Barcode
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dblack uppercase tracking-wider">
            Sku
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dblack uppercase tracking-wider">
            Date
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dblack uppercase tracking-wider">
            Delete
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {jardehProducts.map((row) => (
          <tr className=" border-b border-dplaceHolder" key={row.product_id}>

            <td className=" px-1  w-fit border-r  border-dplaceHolder  ">{row.product_id}</td>
            <td className=" px-1 w-fit border-r  border-dplaceHolder ">{row.barcode}</td>
            <td className=" px-1 w-fit border-r  border-dplaceHolder ">{row.sku}</td>
            <td className=" px-1 w-fit border-r  border-dplaceHolder ">{row.scan_date}</td>
            <td className=" px-1 w-fit border-r  border-dplaceHolder ">
              {/* Checkbox in the last cell */}
              <input
                type="checkbox"
                className="form-checkbox text-indigo-600 transition duration-150 ease-in-out"
             
                onChange={(e) => handleCheckboxChange(e,row.jardeh_product_id)}
              
              />
            </td>
          </tr>
        ))}
      </tbody>

             </table>
             
             
             }
             </div>


             <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default JardehViewAll;