import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaCheckCircle, FaFastBackward, FaFastForward, FaList } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { axiosServer } from "../../../../axiosServer";
import buildLink from "../../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../../contexts/companyCotext";
import MessageAlert from "../../../../components/messageAlert";
import { PlaySounds } from "../../../../functions/playSounds";
import Cookies from "js-cookie";

const ScanPickUpOrderv2 = () => {
  const [usersIds,setUsersIds] = useState([]);
  const hiddenProductId = useRef(null);
  const { id } = useParams();
  const history = useNavigate();
  const [hasproductFocus, setHasproductFocus] = useState(false);
  const [hasbinFocus, setHasBinFocus] = useState(true);
  const {playErrorSound,playSuccessSound} = PlaySounds();
  const [list, setList] = useState();
  const [orederProducts,setOrderProducts] = useState(null);
  const scannedValue = useRef();
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [storeType,setStoreType] = useState(null);
  const [storeTypeVal,setStoreTypeVal] = useState(null);
 


  const biggerThanTen = useRef();
  const [quantityInput, setQuantityInput] = useState();
  const [indexProduct, setindexProduct] = useState(0);
  const [counter,setCounter] = useState(0);
  const [productsPicked,setProductsPicked] = useState(0);
  const [isScannedBin,setIsScannedBin] = useState(false);




  useEffect(()=>{
    if(orederProducts){
      let numberProductPicked = 0
      orederProducts.map((product)=>{
        if(product.picked){
          numberProductPicked += Number(product.quantity);
        }
      })
      setProductsPicked(numberProductPicked);
    }
  },[orederProducts])


  const searchPickupOrderProducts = async () => {
    const userID = getUserIdFromLocalStorage();
    try {
      const url = buildLink("getPickupOrderProducts") + `&order_id=${id}`+ `&user_id=${userID}`;
      const response = await axios.get(url);
     

      if (response.data.success) {
        const newList = response.data.data.list;
        setList(newList);
         setOrderProducts(response.data.data.order_products);
            setUsersIds(response.data.data.stand_bypass_users);
      
      
        scannedValue.current.focus();
      }

      

    } catch (e) {
      console.log("error:", e);
    }
  };
  useEffect(() => {
    
    searchPickupOrderProducts();
  }, []);


  useEffect(()=>{
    scannedValue.current.value = '';
    scannedValue.current.focus()
    setCounter(0)
    setIsScannedBin(false)
    if(orederProducts){
    if(orederProducts[indexProduct].bin_id){
     setStoreType('Bin');
     setStoreTypeVal(orederProducts[indexProduct].floor_name)
    }else if(orederProducts[indexProduct].upc){
      setStoreType('Upc')
      setStoreTypeVal(orederProducts[indexProduct].upc)
    }else if(orederProducts[indexProduct].big_upc){
      setStoreType("BigUpc")
      setStoreTypeVal(orederProducts[indexProduct].big_upc)
    }
  }
  },[indexProduct,orederProducts])

  async function checkShelfLabel(e) {
    e.preventDefault();
    if(scannedValue.current.value !== '' && ( scannedValue.current.value == orederProducts[indexProduct].bin_id || scannedValue.current.value == orederProducts[indexProduct].upc||scannedValue.current.value == orederProducts[indexProduct].big_upc || counter == orederProducts.quantity)){
      setIsScannedBin(true)
      setMessage(`Right ${storeType} for Product! Scan Product to pick it up.`);
      playSuccessSound();
      setShowMessageSucc(true);
      hiddenProductId.current.focus();
      setTimeout(() => {
        setShowMessageSucc(false);
      }, 3000);
    }else{
      setIsScannedBin(false)
      scannedValue.current.value = "";
      setMessage("Bin Not Correct ! ");
      playErrorSound();
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    hiddenProductId.current.value = "";
  }


  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };
  
  async function scanProduct(e) {
    e.preventDefault();

    if( scannedValue.current.value == ""  && !usersIds.includes(getUserIdFromLocalStorage()) &&( scannedValue.current.value !== orederProducts[indexProduct].bin_id || scannedValue.current.value !== orederProducts[indexProduct].upc) ){
      playErrorSound()
      setIsScannedBin(false)
      setMessage(" The value of bin or upc scanned is even empty or incorrect !");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }else{
    const userID = getUserIdFromLocalStorage()
  

    // const obj = {
    //   order_id: id,
    //   label: hiddenProductId.current.value,
    //   quantity: orederProducts[indexProduct].quantity > 10 ? biggerThanTen.current.value:orederProducts[indexProduct].quantity ,
    //   user_id: userID,
    //   upc: orederProducts[indexProduct].upc,
    //   scanned_stand:isScannedBin
    // };

    const obj = {
      order_id: id,
      label: hiddenProductId.current.value,
      quantity: orederProducts[indexProduct].quantity > 10 ? biggerThanTen.current.value : orederProducts[indexProduct].quantity,
      user_id: userID,
      scanned_stand: isScannedBin,
      // Add bin_id, upc, or big_upc based on the conditions
      ...(orederProducts[indexProduct].bin_id
        ? { bin_id: orederProducts[indexProduct].bin_id }
        : orederProducts[indexProduct].upc
        ? { upc: orederProducts[indexProduct].upc }
        : orederProducts[indexProduct].big_upc
        ? { big_upc: orederProducts[indexProduct].big_upc }
        : {})
    };

    
    try {
      const queryParams = new URLSearchParams(obj).toString();
      await axiosServer
        .post(buildLink("pickupOrderProduct") + "&" + queryParams)
        .then((res) => {
    hiddenProductId.current.value = "";
            const data = res.data.data[0]
              //  const   list OrderProducts = res.data.data;
                if(data.pickedup){
                  setIsScannedBin(false)
                 if( indexProduct  !== orederProducts.length - 1){
                  setindexProduct(indexProduct+1)
                 }else{
                  history(-1)
                 }
                  if(data.counter !== "1"){
                    setProductsPicked(Number(productsPicked) + Number(data.counter))
                  }else{
                    setProductsPicked(Number(productsPicked) + 1)
                  }
                
                }else{
                  setCounter(counter + 1)
                  setProductsPicked(Number(productsPicked) + 1)
                }
        });
    } 
    catch (e) {
      // console.log('-------------------------------------------------')
      hiddenProductId.current.value = "";
      playErrorSound()
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      // console.log(e)
    }
  }
    hiddenProductId.current.value = "";
  }

  useEffect(() => {
    const handleBinFocus = () => {
      setHasBinFocus(document.activeElement === scannedValue.current);
    };

    const handleBinBlur = () => {
      setHasBinFocus(false);
    };
    const handleProductFocus = () => {
      setHasproductFocus(document.activeElement === hiddenProductId.current);
    };

    const handleProductBlur = () => {
      setHasproductFocus(false);
    };
    if (scannedValue.current) {
      scannedValue.current.addEventListener("focus", handleBinFocus);
      scannedValue.current.addEventListener("blur", handleBinBlur);
    }
    if (hiddenProductId.current) {
      hiddenProductId.current.addEventListener("focus", handleProductFocus);
      hiddenProductId.current.addEventListener("blur", handleProductBlur);
    }

    return () => {
      if (scannedValue.current) {
        scannedValue.current.removeEventListener("focus", handleBinFocus);
        scannedValue.current.removeEventListener("blur", handleBinBlur);
      }
      if (hiddenProductId.current) {
        hiddenProductId.current.removeEventListener("focus", handleProductBlur);
        hiddenProductId.current.removeEventListener("blur", handleProductBlur);
      }
    };
  }, [scannedValue, hiddenProductId]);



  useEffect(() => {
    const pressButtonIfNeeded = () => {
      if (orederProducts && orederProducts[indexProduct]?.picked && indexProduct !== orederProducts.length - 1) {
        //to automatically click the button
        document.getElementById("nextButton").click();
      }
    };

    pressButtonIfNeeded();
  }, [orederProducts, indexProduct]);



  return (
    <>
      <form
        className=" fixed left-[900px]"
        onSubmit={(e) => {
          checkShelfLabel(e);
        }}
      >
        <input  ref={scannedValue} />
      </form>

      <form
        className=" fixed left-[900px]"
        onSubmit={(e) => {
          scanProduct(e);
        }}
      >
        {" "}
        <input ref={hiddenProductId} />
      </form>

      {/* header */}
      <div className="  sticky top-0 flex flex-col bg-white z-50">
        <div className=" w-full flex flex-row justify-between  bg-dbase h-16">
          <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className=" text-xl my-auto"> Scan PickUp Order</h2>
          </div>

         {/* {indexProduct > 0 && <button onClick={()=>{
             if (indexProduct > 0) {
              setindexProduct(indexProduct - 1);
            } else {
              // history(-1);
            }
         }} className=" mr-10">
          <FaFastBackward className=" text-white" />
         </button>}
        { orederProducts && indexProduct !== orederProducts.length - 1 &&<button onClick={()=>{
              if (indexProduct !== orederProducts.length - 1) {
              setindexProduct(indexProduct + 1);
            } else {
              // history(-1);
            }
         }} className=" mr-10">
          <FaFastForward className=" text-white" />
         </button>} */}
     
        </div>
      </div>
      <div className={` w-full ${productsPicked > 0?" h-16":" h-0"}  transition-all duration-300 relative overflow-hidden bg-greenaalbeit flex justify-start items-start text-white text-xl font-bold container gap-3`}><FaCheckCircle className=" my-auto"/><span className="my-auto">{productsPicked} {" "} product picked  </span></div>
     



{orederProducts &&<> <div className=" relative flex flex-col  pb-28    gap-2">

  <button  onClick={() => scannedValue.current.focus()} className={` ${isScannedBin?" bg-dgreen2":"bg-white"}  text-dblackk flex flex-row  w-full justify-center  text-3xl   py-2  shadow-md `}> <> Scan {" "}<strong>{storeType}</strong></>
   <span class={`relative ${hasbinFocus?"opacity-100":"opacity-0"} transition-all  flex h-3 w-3`}>
  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-dbase opacity-75"></span>
  <span class="relative inline-flex rounded-full h-3 w-3  bg-dbase1"></span>
</span>
  </button>

{ orederProducts[indexProduct].bin_id ?
  <div className=" w-full shadow-md shadow-dbluegray text-center  flex flex-row justify-between  py-3   ">
    <h2 className=" bg-white w-full  font-bold text-3xl ">{orederProducts[indexProduct].floor_name}</h2>
    <h2 className=" bg-white w-full  font-bold text-3xl ">{orederProducts[indexProduct].stand_name}</h2>
    <h2 className={`${orederProducts[indexProduct].bin_color ? `bg-[${orederProducts[indexProduct].bin_color}]`:"bg-white"}  w-full   font-bold text-3xl `}>{orederProducts[indexProduct].bin_name}</h2>
    </div>
:
<div className=" w-full shadow-md shadow-dbluegray text-center  flex flex-row justify-between  py-3   ">
<h2 className=" bg-white w-full  font-bold text-3xl ">{storeTypeVal}</h2>
</div>
}
  <div   onClick={(e) => {
                      if (!e.target.matches("input")) {
                      hiddenProductId.current.focus()
                      }
                    }} className=" flex flex-row gap-2 mt-10 justify-start ">
    
    <div className="  w-[300px] overflow-hidden relative">
      <img className=" relative object-cover w-full h-full" src={orederProducts[indexProduct].mobile_image} />
      
    </div>
  
    <div className=" flex justify-center flex-col gap-1">
     <div className=" justify-start text-left gap-3 flex flex-row "> <h2 className=" w-fit font-bold text-lg bg-dplaceHolder px-4">{orederProducts[indexProduct].sku}</h2>
     <span class={`relative ${hasproductFocus?"opacity-100":"opacity-0"} transition-all  flex h-3 w-3`}>
  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-dbase opacity-75"></span>
  <span class="relative inline-flex rounded-full h-3 w-3  bg-dbase1"></span>
</span>
     </div>
      <h2 className="">{orederProducts[indexProduct].name}</h2>
      {orederProducts[indexProduct].option.name && <h2 className=" flex flex-row justify-start text-start gap-2"><strong>{orederProducts[indexProduct].option.name}</strong><span>{orederProducts[indexProduct].option.value}</span></h2>}
      <div className=" flex flex-row justify-start gap-4">
        <h2 className=" text-dgreen"><span>Qty</span> <strong>{orederProducts[indexProduct].quantity}</strong></h2>
       { orederProducts[indexProduct].picked? <h2 className=" text-dgreen">Product Picked</h2>: <h2 className=" text-dbase"><span>Scanned Value</span> <strong>{counter}</strong></h2>}
        </div>
      {orederProducts[indexProduct].quantity > 10 && (
                            <div className=" flex flex-row justify-start gap-2">
                              <label>Qty</label>
                              <input
                                type="number"
                                ref={biggerThanTen}
                                className="border w-16 border-dhotPink text-center rounded-lg  "
                               
                              />
                            </div>
                          )}
         
    </div>
  </div>
  <div className=" flex justify-between container  mt-10   py-2 ">
 <button disabled={!indexProduct > 0} onClick={()=>{
             if (indexProduct > 0) {
              setindexProduct(indexProduct - 1);
            } else {
              // history(-1);
            }
         }} className={` rounded-full  ${indexProduct > 0 ?" bg-dbase":"bg-dgrey1"} p-2 text-white `}>
          <FaFastBackward className=" text-black" />
         </button>
    <button disabled={indexProduct == orederProducts.length - 1} onClick={()=>{
              if (indexProduct !== orederProducts.length - 1) {
              setindexProduct(indexProduct + 1);
            } else {
              // history(-1);
            }
         }}  className={` rounded-full  ${indexProduct !== orederProducts.length - 1?" bg-dbase":"bg-dgrey1"} p-2 text-white `}>
          <FaFastForward className="  text-black" />
         </button>

  </div>
  </div>
  
  
  

  <button
        id="nextButton"
        onClick={() => {
          if (orederProducts[indexProduct].picked) {
            if (indexProduct !== orederProducts.length - 1) {
              setindexProduct(indexProduct + 1);
            } else {
              history(-1);
            }
          }
        }}
        className={`${
          orederProducts[indexProduct]?.picked
            ? "bg-dbase text-white"
            : "bg-dplaceHolder text-dblack"
        } w-full py-3 font-semibold px-5 fixed bottom-0 left-0 right-0 flex flex-row gap-3 justify-center text-md mt-10`}
      >
        {indexProduct !== orederProducts.length - 1 ? (
          <>
            <div className="flex flex-row justify-between text-center w-full">
              <span>Next Product</span>
              <div className="flex flex-row justify-end gap-4">
              {orederProducts[indexProduct + 1].address ? (
                <>
                  Address: {orederProducts[indexProduct + 1].address}
                </>
              ) : (
                <>
                  UPC: {orederProducts[indexProduct + 1].bin_id
                    ? orederProducts[indexProduct + 1].bin_id
                    : orederProducts[indexProduct + 1].upc
                    ? orederProducts[indexProduct + 1].upc
                    : orederProducts[indexProduct + 1].big_upc || "N/A"}
                </>
              )}
                <FaArrowRight className="my-auto" />
              </div>
            </div>
          </>
        ) : (
          <span>Finish</span>
        )}
      </button>
</>
  }



      <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
    </>
  );
};

export default ScanPickUpOrderv2;
