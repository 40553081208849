import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import buildLink from "../../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../../contexts/companyCotext";
import { LogisticContext } from "../../../../contexts/logisticContext";
import Loader from "../../../../components/loader";
import { IoIosWarning } from "react-icons/io";
import MessageAlert from "../../../../components/messageAlert";
import { OroderStatusHook } from "../../../../functions/updateOrodersStatus";
import hocStarredPage from "../../../../components/hocStarredPage";

const Givenv2 = () => {
  const orderId = useRef(null);
  const options = [
    { value: "normal", label: "Normal" },
    { value: "return", label: "Return" },
  ];
  const [stateLogistic, dispatchStateLogistic] = useContext(LogisticContext);

  const {
    updateOrderStatus,
    showMessgErr,
    setorderStatus,
    numPackage,
    setNumPackage,
    setLogisticSelect,
    setOrdersList,
    orderList,
    message,
    showMessgSucc,
    loading,
    handleRadioChange,
  } = OroderStatusHook();

  const history = useNavigate();

  useEffect(() => {
    orderId.current.focus();
  }, []);

  const handleUpdateOrderStatus = async (e) => {
   await updateOrderStatus(e, "given", orderId.current.value)
    orderId.current.value = '';
    orderId.current.focus();
  }

  const handleTrashClick = () => {
    orderId.current.value = "";
    orderId.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Given</h2>
            </div>
          </div>
        </div>
        <div className=" container">
          <div className=" py-3">
            <div>order status</div>
            <Select
              placeholder="Order Status"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "red",
                  padding: "8px 5px",
                }),
              }}
              onChange={(e) => {
                setorderStatus(e.value);
              }}
              options={options}
            />
          </div>
          <div className=" py-3">
            <div>logistics</div>
            <Select
              placeholder="Logistics"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "red",
                  padding: "8px 5px",
                }),
              }}
              options={stateLogistic.logisticList}
              onChange={(e) => {
                setLogisticSelect(e.value);
              }}
            />
            <div className="p-1 mt-3">Number of Packages:</div>
            <div className="space-x-3 flex flex-wrap">
            <input
            defaultValue={1}
            onChange={handleRadioChange}
              type="text"
              className="border mb-3 border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
              placeholder="Order Id"
            />
            </div>
          </div>
          <div className="-mt-2 -mb-2">Order ID</div>
          <form
            onSubmit={(e) =>
            handleUpdateOrderStatus(e)
            }
            className="gap-2 flex py-3"
          >
            <div className="relative flex-1">
              <input
                ref={orderId}
                type="text"
                className="border-2 w-full rounded-md border-dbase py-2 pr-8 pl-2"
              />
              <FaTrash
                className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                onClick={handleTrashClick}
              />
            </div>
            <div className="w-fit cursor-pointer px-5 text-white text-center rounded-md bg-dbase">
              <button className="flex  whitespace-nowrap  items-center justify-center h-full">
                {numPackage > 1 || numPackage === 0 ? (
                  numPackage
                ) : loading ? (
                  <Loader />
                ) : (
                  "Mark as given"
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="container w-full rounded-md text-white flex justify-between ">
          <div className="border w-full flex-1  px-8  text-dgreyBlack border-dblack  py-2 flex items-center justify-center">
          {orderList.filter(order => order.success).length}
          </div>
          <div
            onClick={() => setOrdersList([])}
            className="border cursor-pointer w-full bg-dbase   flex-2  border-dblack  py-2 flex items-center justify-center"
          >
            Reset
          </div>
        </div>
        <div class="w-full mt-3 bg-white p-3 rounded">
          <table class="w-full border-dlabelColor border">
            <thead>
              <tr className=" border-b border-dlabelColor">
                <th class="py-2  border-dlabelColor border-r">Order ID</th>
                <th class="py-2 border-r">Status</th>
                <th class="py-2 border-dlabelColor">Reason</th>
              </tr>
            </thead>
            <tbody>
              {orderList &&
                orderList.map((item) => {
                  return (
                    <tr
                      className={` ${
                        item.success ? "text-dgreen" : "text-dbase"
                      } text-center border-b border-dlabelColor`}
                    >
                      <td class="py-2  border-dlabelColor border-r">
                        {item.id}
                      </td>
                      <td class="py-2  border-dlabelColor border-r">
                        {item.status}
                      </td>
                      <td class="py-2  border-dlabelColor border-r">
                        {item.reason}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(Givenv2);
