import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../urls";
import { CompanyContext } from "../../contexts/companyCotext";
import Loader from "../../components/loader";
import { IoIosWarning } from "react-icons/io";
import MessageAlert from "../../components/messageAlert";
import { PlaySounds } from "../../functions/playSounds";
import hocStarredPage from "../../components/hocStarredPage";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import useDeviceSize from "../../components/useDeviceSize";
import "swiper/css";
import "swiper/css/pagination";

const Product = () => {
  const history = useNavigate();
  const [productInf, setProductInf] = useState(null);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const codePr = useRef("");
  const [keyboardType,setKeyboardType] = useState("text");
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const {playErrorSound,playSuccessSound} = PlaySounds();
  const [width] = useDeviceSize();
  const [host, setHost] = useState(window.location.host);

  useEffect(() => {
    setHost(window.location.host);
  }, [host]);

  
  const searchProduct = async (e) => {
    console.log(stateCom.company);
    e.preventDefault();
    setLoading(true);
    const item = codePr.current.value;
    codePr.current.blur()
    try {
      const url = buildLink("productInfo") + `&item=${item}&test=true` + `&user_id=${stateCom.userID}`;
      await axios.get(url).then((response) => {
        setKeyboardType("none")
        if (response.data.success) {
          setProductInf(response.data.data);
        }else{
     
        }
      });
      setLoading(false);
    } catch (e) {
    
      playErrorSound();
      setLoading(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  useEffect(() => {
    codePr.current.focus();
  }, []);
  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Product</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <form onSubmit={(e) => searchProduct(e)}>
            <div className=" flex flex-row justify-center gap-3 ">
              <div className="relative flex-1">
                <input
                onFocus={()=>{setKeyboardType("text")}}
                inputMode="" 
                  ref={codePr}
                  type={keyboardType}
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Enter Code or SKU"
                />
                <div>
                  <FaTrash onClick={()=>handleTrashClick()} className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loading ? <Loader /> : <FaSearch />}{" "}
              </button>
            </div>
          </form>
        </div>

        <div className="container ">
          {productInf && (
            <div className=" h-full flex flex-col justify-center text-center gap-3">
              <div className=" flex px-2 py-2 flex-row my-2 container justify-between w-full">
              <div
                  className={` ${width > 650 ? "w-fit h-fit px-40 py-40 -mt-32" : "w-fit mt-32 h-fit"}`}
                  style={{ width: "40%" }}
                >
                  {productInf.images && productInf.images.length > 0 ? (
                    <Swiper
                      pagination={true}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {productInf.images.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            className= "relative cursor-pointer border border-slate200 px-1 py-1 w-full h-full"
                            src={image}
                            alt={`Image ${index}`}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <img
                      className="object-cover w-full h-full"
                      src={productInf.image}
                    />
                  )}
                </div>
                <div>
                {productInf.option_name  &&  <div>
                   <h2 className=" text-start font-bold">Options :</h2>
                    <table className="table-auto w-full border">
                      <tbody>
                      <tr>
                         { productInf.option_name&& <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            {productInf.option_name} :{" "}
                            {productInf.option_value_name}
                          </td>}
                          {productInf.option_quantity >0 && <td className="border text-center border-dlabelColor px-2 py-2">
                            Option Qty : {productInf.option_quantity}
                          </td>}
                        </tr>
                        <tr>
                          <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            Upc: {productInf.upc}
                          </td>
                          <td className="border text-center border-dlabelColor px-2 py-2">
                            BarCode:{" "}
                            {productInf.barcode === ""
                              ? "_"
                              : productInf.barcode}
                          </td>
                        </tr>
                        <tr> <td className="border text-center border-dlabelColor px-2 py-2">
                            Big Upc:{" "}
                            {productInf.big_upc === ""
                              ? "_"
                              : productInf.big_upc}
                          </td></tr>
                      </tbody>
                    </table>
                  </div>

          }
                  <div className=" md:m-40" style={{ width: "50%" }}>
                    <h2 className=" text-start font-bold">Product :</h2>
                    <table className="table-auto w-full border">
                      <tbody>
                        <tr>
                          <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            Sku: {productInf.sku}
                          </td>
                          <td className="border text-center border-dlabelColor px-2 py-2">
                            Upc: {productInf.upc}
                          </td>
                        </tr>
                        <tr>
                          <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            Qty: {productInf.quantity}
                          </td>
                          <td className="border text-center border-dlabelColor px-2 py-2">
                            BarCode:{" "}
                            {productInf.barcode === ""
                              ? "_"
                              : productInf.barcode}
                          </td>
                        </tr>
                        <tr>
                          <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            Fake Qty:{" "}
                            {productInf.fake_quantity === ""
                              ? "_"
                              : productInf.fake_quantity}
                          </td>
                          <td className="border text-center border-dlabelColor px-2 py-2">
                            Id:{" "}
                            {productInf.product_id === ""
                              ? "_"
                              : productInf.product_id}
                          </td>
                        </tr>
                        <tr>
                          <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            Old Upc:{" "}
                            {productInf.previous_upc === ""
                              ? "_"
                              : productInf.previous_upc}
                          </td>
                        {!productInf.option_name&&  <td className="border text-center border-dlabelColor px-2 py-2">
                            Big Upc:{" "}
                            {productInf.big_upc === ""
                              ? "_"
                              : productInf.big_upc}
                          </td>
          }
                        </tr>
                        <tr>
                          <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            Sku: {productInf.sku === "" ? "_" : productInf.sku}
                          </td>
                          <td className="border text-center border-dlabelColor px-2 py-2">
                            Old Main Upc:{" "}
                            {productInf.main_upc === ""
                              ? "_"
                              : productInf.main_upc}
                          </td>
                        </tr>
                        <tr>
                          <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            Mic Upc:{" "}
                            {productInf.min_upc_quantity === ""
                              ? "_"
                              : productInf.min_upc_quantity}
                          </td>
                          <td className="border text-center border-dlabelColor px-2 py-2">
                            Max Upc:{" "}
                            {productInf.max_upc_quantity === ""
                              ? "_"
                              : productInf.max_upc_quantity}
                          </td>
                        </tr>
                        <tr>
                          <td className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">
                            Upc Qty:{" "}
                            {productInf.upc_quantity === ""
                              ? "_"
                              : productInf.upc_quantity}
                          </td>
                          <td className="border text-center border-dlabelColor px-2 py-2">
                            Location:{" "}
                            {productInf.location === ""
                              ? "_"
                              : productInf.location}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className=" flex flex-col justify-center gap-5">
              
              <h2 className=" font-semibold"> Price: {productInf.special_lbp?productInf.special_lbp:productInf.price_lbp?productInf.price_lbp:""} - {productInf.special?productInf.special:productInf.price?productInf.price:""}</h2>

              
              {host.startsWith("localhost") || host === "pda.flo-lebanon.com" && (<h2 className="font-semibold"> Profit Percentage: {productInf.profit_percentage}</h2>)}

            {host.startsWith("localhost") || host === "pda.flo-lebanon.com" && <h2 className=" font-bold"><strong>Cost:</strong>{productInf?.cost?productInf?.cost:" _"}</h2>}
              </div>
              <div className=" w-full rounded-lg border   bg-dplaceHolder py-3">
                In Stand Qty : {productInf.in_stand_quantity && productInf.in_stand_quantity}
              </div>

              {productInf.statusGroupQty.order.length>0 && (
              <div className=" border rounded-md  mt-3 divide-dlabelColor border-dlabelColor flex justify-between  ">
                <div className="  border-dlabelColor w-1/2 border-r  px-4 py-2">
                  order-{productInf.statusGroupQty.order[0].status_group_name}
                </div>
                <div className=" w-1/2  text-center px-4 py-2">
                  {productInf.statusGroupQty.order[0].quantity}
                </div>
              </div>
            )}


              {productInf.statusGroupQty.return.length >0 && (
                <div className=" border rounded-md border-dlabelColor flex flex-col  ">
                {productInf.statusGroupQty.return.map((item)=>  <div className="   border-b  border-dlabelColor  flex justify-between ">
                <div className="  border-dlabelColor w-1/2 border-r border-dl  px-4 py-2">

                  Return-{item.status_group_name}
                </div>
                <div className=" w-1/2  px-4 py-2">
                  {item.quantity}
                </div>
              </div>
                )}
                </div>
              
              )}


              { productInf.manufacturer && <div className=" w-full flex justify-center rounded-t-md border border-dlabelColor  font-bold py-1"> Manufacturer : {productInf.manufacturer}</div>}
              {productInf.product_filter&&  <div className=" w-full flex justify-center rounded-t-md border border-dlabelColor   flex-col font-bold py-1"> <strong>Products Filters</strong>
              <div className=" mt-3">
              { productInf.product_filter.map((item)=>(<h2 className=" font-normal">{item.filter_group_name}:  {item.filter_name}</h2>))}</div>
              </div>}
              {/* {productInf.statusGroupQty.return[0].quantity && (
                <div className=" border rounded-full divide-dlabelColor border-dlabelColor flex justify-between divide-x-2">
                  <div className=" rounded-2xl w-1/2 whitespace-nowrap  px-4 py-2">
                    Return-Send To Stock
                  </div>
                  <div className=" w-1/2  px-4 py-2">
                    { productInf.statusGroupQty.return[0].quantity}
                  </div>
                </div>
              )} */}
              
            { productInf.all_options.length>0&&
                    <table className= " mt-5 table-auto w-full rounded-md border">
                      <tbody>
                        <tr>
                          <th className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">{productInf.all_options[0].name}</th>
                          <th className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap">QTY</th>
                        </tr>
                     {productInf.all_options[0].product_option_values.map((option)=>(
                       <tr>
                          <td className={`  border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap`}>
                          {option.name} 
                        </td>
                        <td className={`border ${option.quantity < 5 ? "text-dyellow":"text-dgreen"} text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap`}>
                          {option.quantity} 
                        </td>
                        </tr>
                     ))} 

                        
                       
                        </tbody>
                        </table>

                     }
            </div>
          
          )}





        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(Product);
