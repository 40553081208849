import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaPaperPlane,
  FaPlusCircle,
  FaSearch,
  FaShoppingCart,
  FaSyncAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NavigateButton from "../../../components/buttonsNavigate";
import { LuBoxes } from "react-icons/lu";
import { BsDatabaseFillCheck } from "react-icons/bs";
const ProductPage = () => {
  const [host, setHost] = useState("");
  useEffect(() => {
    setHost(window.location.host);
  }, [host]);
  const colors = [
    "bg-[#BF1B26]",
    "bg-[#0D6E99]",
    "bg-[#5E2D79]",
    "bg-[#4CAF50]",
    "bg-[#333333]",
    "bg-[#CCCCCC]",
    "bg-[#FF5733]",
    "bg-[#FFC300]",
    "bg-[#581845]",
    "bg-[#FF851B]",
    "bg-[#7D3C98]",
    "bg-[#01A9DB]",
    "bg-[#7FF050]",
    "bg-[#FF007F]",
    "bg-[#ADD8E6]",
    "bg-[#7EE050]",
  ];

  const history = useNavigate();
  return (
    <>
      {/* header */}
      <div className=" mb-5 sticky top-0 flex flex-col bg-white">
        <div className=" w-full bg-dbase h-16">
          <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className=" text-xl my-auto">Product Page</h2>
          </div>
        </div>
      </div>

      {/* buttons navigations */}
      <div className=" grid grid-cols-2 container gap-x-6 gap-y-4 mb-5">
        <>
          <NavigateButton
            icon={<FaSearch />}
            name={"PRODUCT"}
            arname={" المنتج"}
            color={colors[0]}
            onclick={() => history("product")}
          />
          {/* { host == 'pda.ishtari.com' || host.startsWith("localhost") &&
          <NavigateButton
            icon={<FaShoppingCart />}
            name={"RECEIVE PRODUCT QTY"}
            // arname={"إعادة التعبئة "}
            color={colors[1]}
            onclick={() => history("receiveproductquantity")}
          />
 } */}
          <NavigateButton
            icon={<FaShoppingCart />}
            name={"RECEIVE PRODUCT QTY"}
            // arname={"إعادة التعبئة "}
            color={colors[1]}
            onclick={() => history("receiveproductquantity")}
          />
          <NavigateButton
            icon={<BsDatabaseFillCheck />}
            name={"Check Product"}
            arname={" "}
            color={colors[15]}
            onclick={() => history("checkproduct")}
          />
          <NavigateButton
            icon={<LuBoxes />}
            name={"Bin"}
            arname={" "}
            color={colors[3]}
            onclick={() => history("bin")}
          />
          <NavigateButton
            icon={<FaSyncAlt />}
            name={"CHANGE UPC"}
            arname={"تغيير الخانة "}
            color={colors[4]}
            onclick={() => history("changeupc")} />
          <NavigateButton
            icon={<FaShoppingCart />}
            name={"UPC/BIN RESTOCK"}
            arname={"إعادة التعبئة "}
            color={colors[5]}
            onclick={() => history("upcrestock")}
          />
          <NavigateButton
            icon={<FaShoppingCart />}
            name={"BIG UPC RESTOCK"}
            color={colors[6]}
            onclick={() => history("bigupcrestock")}
          />
          <NavigateButton
            icon={<FaSyncAlt />}
            name={"CHANGE OPTION UPC"}
            arname={"تغيير الخانة "}
            color={colors[7]}
            onclick={() => history("changeoptionupc")}
          />
          <NavigateButton
            icon={<FaSyncAlt />}
            name={"CHANGE BIG UPC"}
            arname={"تغيير الخانة الكبيرة "}
            color={colors[8]}
            onclick={() => history("changebigupc")}
          />
          <NavigateButton
            icon={<FaSyncAlt />}
            name={"CHANGE MAIN UPC"}
            arname={" تغيير الخانة الرئيسية"}
            color={colors[9]}
            onclick={() => history("changemainupc")}
          />

          <NavigateButton
            icon={<FaShoppingCart />}
            name={"CHANGE UPC/Bin Quantity"}
            arname={" "}
            color={colors[10]}
            onclick={() => history("changeupcquantity")}
          />
          <NavigateButton
            icon={<FaShoppingCart />}
            name={"MANAGE UPC/Bin Quantity"}
            arname={" "}
            color={colors[11]}
            onclick={() => history("manageupcquantity")}
          />
          <NavigateButton
            icon={<FaCheckCircle />}
            name={"CHECK UPC/Bin"}
            arname={"   "}
            color={colors[12]}
            onclick={() => history("checkupc")}
          />

          <NavigateButton
            name={"CHANGE PRODUCT STATUS"}
            icon={<FaSyncAlt />}
            color={colors[13]}
            onclick={() => history("changeproductstatus")}
          />
          <NavigateButton
            icon={<FaPlusCircle />}
            name={"JARDEH"}
            arname={"جردة "}
            color={colors[14]}
            onclick={() => history("jardeh")}
          />
        </>
      </div>
    </>
  );
};

export default ProductPage;
