import React from "react";
import NavigateButton from "../../../components/buttonsNavigate";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaHistory,
  FaListUl,
  FaSearch,
  FaShoppingCart,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LiaTruckPickupSolid } from "react-icons/lia";

const OrderPage = () => {
  const history = useNavigate();
  return (
    <>
      {/* header */}
      <div className=" mb-5 sticky top-0 flex flex-col bg-white">
        <div className=" w-full bg-dbase h-16">
          <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className=" text-xl    my-auto">Order</h2>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-2 container gap-x-6 gap-y-4">
        <>
          <NavigateButton
            icon={<FaHistory />}
            name={"CHANGE STATUS"}
            arname={"تغيير الحالة"}
            color={"bg-Orangeflo"}
            onclick={() => history("changestatus")}
          />
          <NavigateButton
            icon={<FaSearch />}
            name={"SEARCH ORDER"}
            arname={"الطلبية"}
            color={"bg-[rgb(14,165,233)]"}
            onclick={() => history("order")}
          />
          <NavigateButton
            name={"Get Data Entry Product"}
            icon={<FaShoppingCart />}
            arname={" بيانات المنتج المدخلة "}
            color={"bg-dbase"}
            onclick={() => history("getorder")}
          />
          <NavigateButton
            icon={<FaListUl />}
            name={"ORDER PRODUCT"}
            arname={" تحضير المنتج"}
            color={"bg-[rgb(245,158,11)]"}
            onclick={() => history("orderproduct")}
          />
          <NavigateButton
            icon={<FaHistory />}
            name={"ORDERS HISTORY"}
            arname={" تاريخ الطلبات"}
            color={"bg-[rgb(7,89,133)]"}
            onclick={() => history("ordershistory")}
          />
          <NavigateButton
            icon={<LiaTruckPickupSolid />}
            name={"PickUp Order"}
            arname={" استلام الطلبية"}
            color={"bg-[rgb(145,170,255)]"}
            onclick={() => history("pickuporder")}
          />
        </>
      </div>
    </>
  );
};

export default OrderPage;
